async function buscaEscolaByCity(data) {
    const resp = await fetch(process.env.VUE_APP_API_ORIGIN + '/api/schools', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data),
    });
    if (!resp.ok) {
        throw {cod: '27GI4NHV'};
    }
    const respBody = await resp.json();

    if (respBody.erro) {
        throw {cod: 'ZJN554BB'};
    }

    return {
        schools: respBody.data,
    };
}

export const consultaEscolas = (data) => buscaEscolaByCity(data);
