import fetchNewRegister from "./fetch/fetchNewRegister";
import fetchNewAereoTermo from "./fetch/fetchNewAereoTermo";
import fetchNewTermo from "./fetch/fetchNewTermo";

async function apiFetch(form1Data) {
    if (!form1Data) {
        throw {cod: 'VPN7D2VT'};
    }
    let formData = makeForm(form1Data);
    let resp;
    resp = await fetchNewRegister(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        if (errRespBody.errors && errRespBody.errors.email[0] === "O campo email já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O e-mail cadastrado já encontra-se em uso, confira sua caixa de e-mails"};
        }
        if (errRespBody.errors && errRespBody.errors.cpf[0] === "O campo CPF já está sendo utilizado.") {
            throw {cod: 'EUE65DJC', errMsg: "O CPF cadastrado já encontra-se em uso"};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data && !respBody.user) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
    // return formData.get('name');
}

async function apiFetchAereo(formData) {
    if (!formData) {
        throw {cod: 'VPN7D2VT'};
    }
    let resp;
    resp = await fetchNewAereoTermo(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
}

async function apiFetchTermo(formData) {
    if (!formData) {
        throw {cod: 'VPN7D2VT'};
    }
    let resp;
    resp = await fetchNewTermo(formData);

    if (!resp.ok) {
        let errRespBody;
        try {
            errRespBody = await resp.json();
        } catch {
            throw {cod: 'DY6YRVUV', errMsg: errRespBody.message};
        }
        throw {cod: 'EUE65DJC', errMsg: errRespBody.message};
    }

    const respBody = await resp.json();


    if (!respBody.data) {
        throw {cod: '5KJM357A'};
    }
    return respBody.data;
}

function makeForm(inputData) {
    let formData = new FormData();
    formData.append('authorizes_data_usage', 1);
    formData.append('state', inputData.state.value);
    formData.append('city', inputData.city.nome);
    formData.append('school', inputData.school.name);
    formData.append('school_sge', inputData.sge);
    formData.append('school_convenio', inputData.convenio);
    formData.append('school_idarco', inputData.idarco);
    formData.append('name', inputData.name);
    formData.append('badge_name', inputData.badge_name);
    formData.append('email', inputData.email);
    formData.append('cpf', inputData.cpf);
    formData.append('rg', inputData.rg);
    formData.append('role', inputData.role.value);
    formData.append('gender', inputData.gender.label);
    formData.append('pcd', inputData.pcd.label);
    formData.append('restriction_food', inputData.restriction_food.label);

    if (inputData.documentPhoto) {
        let ext = inputData.documentPhoto.type.split('/')[1]
        formData.append('documentPhoto', inputData.documentPhoto, 'documentPhoto.' + ext)
    }

    formData.append('departure_date', inputData.departure_date.label);
    formData.append('return_date', inputData.return_date.label);
    formData.append('transport_type', inputData.type_transport.label);
    formData.append('busstation_origin', inputData.busstation_origin || null);
    formData.append('airport_origin', inputData.airport_origin ? inputData.airport_origin.value : null );
    formData.append('room_type', inputData.room_type ? inputData.room_type.value : null);
    formData.append('name_companion', inputData.name_companion);

    return formData;
}
export async function register(form1Data) {
    if (!form1Data) {
        throw '5KJM357A';
    }
    console.log(form1Data);


    return await apiFetch(form1Data);
}


export async function sendAereoFile(formData) {
    if (!formData) {
        throw '5KJM357A';
    }
    console.log(formData);


    return await apiFetchAereo(formData);
}


export async function sendTermo(formData) {
    if (!formData) {
        throw '5KJM357A';
    }
    console.log(formData);


    return await apiFetchTermo(formData);
}

