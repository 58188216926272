<template>
  <div>
    <header>
      <div class="navbar flex items-center justify-center relative z-10">
        <nav class="flex flex-wrap items-center justify-between w1200 py-4 md:py-0 px-4 text-lg">
          <div>
            <a href="/">
              <img src="../assets/logo.png" class="mr-3 h-12 sm:h-14" alt="logotipo" />
            </a>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" id="menu-button" class="h-6 w-6 cursor-pointer md:hidden block" @click="openMenu"
          fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <div class="hidden w-full md:flex md:items-center md:w-auto" id="menu">
          <ul class="menu-itens md:flex md:justify-between md:pt-0 lg:m-3 md:px-10 mt-4">
            <li>
              <a class="item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-2 border-current/50 block"
                href="/">Home</a>
            </li>
            <li>
              <a class="item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-2 border-current/50 block" href="#sobre" @click="closeMenu">O evento</a>
            </li>
            <li>
              <a class="item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-2 border-current/50 block" @click="closeMenu"
                href="#local">Local</a>
            </li>
            <!-- <li>
              <a class="item-menu py-2 md:px-4 md:py-2 md:border-b-0 border-b-[1px] border-current/50 block"
                href="#palestrantes">Palestrantes</a>
            </li> -->
          </ul>
          <button>
            <a class="hidden md:block button-subscribe py-2 md:px-10 md:py-2 block rounded-md items-center" href="#" @click="openModalForm()"
              >INSCREVA-SE</a>
          </button>
        </div>
        </nav>
      </div>
      <section class="header-top">
        <div class="header-container">
          <div class="header-back"></div>
        </div>
        <figure class="event-cover header-back"></figure>
      </section>
      <nav class="nav-inscricao-mobile fixed bottom-0 inset-x-0 justify-between text-sm text-white uppercase font-bold block z-[99999] md:hidden lg:hidden" id="inscricao-mobile">
        <a href="#" @click="openModalForm()" class="w-full block py-4 px-3 text-center font-bold transition duration-300"> Quero me inscrever </a>
      </nav>
    </header>
    <main class="flex justify-center">
      <Content @openModal="openModalForm" />
      <!-- <slot :openModal="openModalForm"/> -->
    </main>
    <Drawer
      @close="openModalForm"
      :align="'right'"
      :closeable="true"
      :maskClosable="false"
      :zIndex="100002"
    >
      <div v-if="openRegister">
        <div class="cursor-pointer text-gray-600 w-full items-center border-b border-t-neutral-700 border-solid px-2 pb-4">
          <h2 class="text-sm font-semibold text-gray-900" id="slide-over-title">INSCRIÇÃO - INFORMAÇÕES PESSOAIS</h2>
        </div>
        <Register />
      </div>
    </Drawer>
    <section class="bg-white">
      <div class="max-w-screen-xl px-4 py-2 mx-auto space-y-4 overflow-hidden sm:px-6 lg:px-8">

      <p class="mt-8 text-base leading-6 text-center text-gray-400">
      © 2024 Encontro de Escolas Católicas. Todos os direitos reservados
      </p>
        <div class="flex justify-center items-center gap-2.5">
          <p class="font-inter text-sm font-normal text-center text-gray-500 opacity-80">Feito com 💖 por:</p>
          <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.22731 0.432367L7.04939 4.37495C7.28865 4.53746 7.42258 4.84294 7.42258 5.22666C7.42258 5.61039 7.28865 5.96402 7.04939 6.2093L1.22731 12.1984C0.974504 12.4587 0.678058 12.5219 0.416223 12.3715C0.154387 12.221 0.00390625 11.9005 0.00390625 11.4957V1.56398C0.00390625 1.15918 0.154387 0.784489 0.416223 0.543721C0.678058 0.301448 0.974504 0.26082 1.22731 0.432367Z" fill="url(#paint0_linear_2283_7250)"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66502 2.73508C4.29843 4.39036 6.93183 6.04564 9.56523 7.70092C9.89027 7.90557 10.0724 8.23513 10.0724 8.61885C10.0724 9.00258 9.89027 9.33213 9.56523 9.53678C6.93183 11.1921 4.29843 12.8473 1.66502 14.5026C1.32193 14.7178 0.918645 14.7298 0.565017 14.5342C0.209884 14.3386 0.00523055 13.991 0.00523055 13.5862V2.00374C-0.00078866 1.55983 -0.0218574 1.143 0.212892 0.796891C0.480746 0.399623 0.906606 0.137788 1.35654 0.520008C0.267065 1.23178 1.20606 2.46722 1.66502 2.73658V2.73508Z" fill="url(#paint1_linear_2283_7250)"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66458 2.73467C4.29798 4.38995 6.93138 6.04523 9.56479 7.70051C9.84317 7.87507 10.0162 8.14142 10.0599 8.45442C8.5039 9.58603 6.43028 10.2767 4.15201 10.2767C2.65022 10.2767 1.23872 9.97577 0.00327715 9.44759V2.00032C-0.00274206 1.55641 -0.0238085 1.13958 0.210941 0.793473C0.478795 0.396205 0.904655 0.13437 1.35459 0.51659C0.265114 1.22836 1.20411 2.4638 1.66308 2.73316L1.66458 2.73467Z" fill="url(#paint2_linear_2283_7250)"></path>
            <defs>
              <linearGradient id="paint0_linear_2283_7250" x1="3.37316" y1="7.61629" x2="-1.64235" y2="5.22366" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FBAD18"></stop>
                <stop offset="1" stop-color="#EE2624"></stop>
              </linearGradient>
              <linearGradient id="paint1_linear_2283_7250" x1="3.56108" y1="6.14345" x2="-3.40315" y2="9.92201" gradientUnits="userSpaceOnUse">
                <stop stop-color="#009EC0"></stop>
                <stop offset="1" stop-color="#0054A1"></stop>
              </linearGradient>
              <linearGradient id="paint2_linear_2283_7250" x1="1.79399" y1="5.27778" x2="9.97861" y2="7.24456" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00A5B7"></stop>
                <stop offset="1" stop-color="#00589A"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Drawer from "vue-simple-drawer";
import Register from './register.vue';
import Content from './content.vue';

export default {
  name: "BaseLayout",
  components: { Drawer, Register, Content },
  data () {
    return {
      openRegister: false,
      openAereoTermo: false,
    }
  },
  methods: {
    openModalForm () {
      this.openRegister = !this.openRegister;
    },
    openModalAereoTermo () {
      this.openAereoTermo = !this.openAereoTermo;
    },
    openMenu () {
      const menu = document.querySelector('#menu');
      menu.classList.toggle('hidden');
    },
    closeMenu() {
      const menu = document.querySelector('#menu');
      menu.classList.toggle('hidden');
    }
  }
}
</script>

<style scoped lang="scss">
</style>
